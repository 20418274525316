// Shadows_____________________________________
/* long */
$shadow-long: 1px 5px 12px -4px rgba(140, 130, 154, 0.10);

// Colors_____________________________________
// Surfaces
$surface1: #F4F5F6;
$surface2: #EBEDEF;

// Texts
$text-secondary: #5C5E63;
$text-color: #2F3237;

// Gray/Accents/Green Text
$gray-accents-green-text: #085A39;
$gray-accents-green-100: #C7F4D2;
$gray-accents-green-20: #DDF9E3;
$gray-accents-spark-green-100: #EFF9B5;
$gray-accents-light-blue-100: #C5EDF9;
$gray-accents-red-orange-100: #FEE0C8;
$gray-accents-light-purple-100: #D6E4FF;


$white: #FFF;

// Borders
$border-surface2: 1px solid $surface2;

// Backgrounds
$background-white: $white;

// Fonts
$font-family: Inter;
