@import 'constants.scss';

.richTextEditorMainContainer {
    
    .richTextEditorContainer {
       
        .fileInput {
            display: none;
        }

        :global {
            .tox {
                .tox-editor-header {
                    visibility: hidden;
                    height: 0;
                    padding: 0;
                }
                &.tox-tinymce {
                    background: $formElementBackground;
                    border: 1px solid transparent;
                }
            }

            .tox-sidebar {
                position: fixed;
                right: 0;
                top: 210px;
                z-index: 25;
                background: transparent;
                
                // backdrop-filter: blur(1px);

                // .tox-sidebar__slider {
                //     background: rgba(217, 217, 217, 0.4);
                // }
            }
            .tox-edit-area__iframe {
                background: transparent;
                &:hover{
                    background: #E8E8E8;
                }
                border-radius: 6px;
            }
            
        }

        &.focused:not(.readOnly) {
            :global {
                .tox {
                    
                    &.tox-tinymce {
                        border: 1px solid $primaryColor;
                    }

                    .tox-edit-area__iframe {
                    
                
                        background-color: white;
                       
                        
                    
                    }
                }
            }
        }

        &.readOnly {
            
            :global {
                .tox-edit-area__iframe {
                    
                
                    background-color: transparent;
                   
                    &:hover{
                        background-color: transparent;
                    }
                
                }
            }
        }
        

        &.error {
            border-color: $fieldErrorColor;
            color: inherit;
            &.focused {
                border-color: $fieldErrorColor;
            }

            &.filled, &.standard {
                &.focused {
                    border-color: $fieldErrorColor;
                }
            }
        }

        &.focused { 
            // border: 1px solid $secondaryColor;

            :global {
                .tox {
                    .tox-editor-header {
                        visibility: visible;
                        height: inherit;
                    }
                }
            }
            

            .filesContainer {
                .filesList {
                    .file {
                        &:hover{
                            .overlay{
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        &.filled{
            
            font-size: 14px;
            background: transparent;
            p{
                margin: 0;
            }
            border-color: transparent;
           
        
            &.focused {
                .richTextEditor {
                    background: #fff;
                }
                // border: 1px solid $primaryColor;
                a {
                    cursor: pointer;
                }
            }
        
            &.readOnly{
                &:hover{
                    background: inherit;
                }
            }
        
        }
        .richTextEditor {
            border: none;
            // border-radius: 6px;
            padding: 6px;
            width: 100%;
            background-color: transparent;
            box-sizing: border-box;
            :global {
                .public-DraftEditor-content, .public-DraftEditorPlaceholder-root {
                    padding: 0;
                }
            }

            .root {
                width: 100%;
                font-family: $fontFamily;
                font-size: 14px;
               
            }

            

            .toolbar {
                padding: 0;
                margin: 0 0 10px 0;
                background-color: #F9F9F9;
                button, .toolbarButton  {
                    border: none;
                    display: inline-block;
                    width: auto;
                    cursor: pointer;
                    
                }

                .uploadButton{
                    width: 28px;
                    height: 30px;
                    padding: 4px;
                }
            }

            
        
        }

        .filesContainer {
            padding: 0 0 10px;

            .separator {
                border-bottom-color: #C4C4C4;
            }
            .filesList {
                margin-top: 8px;
                flex-wrap: wrap;

                .file {
                    margin-left: 4px;
                    margin-bottom: 4px;
                    width: 176px;
                    height: 124px;
                    cursor: zoom-in;
                    position: relative;

                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: 50%;
                    background-color: #C4C4C4;


                    .iconBox{
                        cursor: pointer;
                        width: 100%;
                        height: 100%;
                        .icon{
                            width: 24px;
                            margin: auto;
                        }
                    }

                    .googleDoc {
                        color: #4885ed;
                    }
                    .pdf {
                        color: #F40F02;
                    }
                    .fileIcon{
                        color: #5a5a5a;
                    }

                    .overlay {
                        width: 100%;
                        height: 24px;
                        position: absolute;
                        bottom: 0px;
                        color: $white;
                        justify-content: end;
                        background: rgb(53 53 53 / 45%);
                        opacity: 0;
                        transition: all .3s;

                        .resourcesCloseIcon {
                            cursor: pointer;
                        }
                    }

                    &:hover {
                        .overlay {
                            opacity: 1;
                        }
                    }
                
                }
            }
        }
    }
    
    
    
    .label {
        font-size: 12px;
        line-height: 21px;
        color: $formElementLabel;
        justify-content: space-between;
        .required{
            color: $requiredFieldColor;
            font-size: 14px;
        }
    }

    .error {
        font-size: 12px;
        color: $fieldErrorColor;
    }
}

:global {
    .tox {
        &.tox-tinymce-aux {

            .tox-pop {
                .tox-pop__dialog {
                    border-radius: unset;
                    .tox-toolbar {
                        padding: 0;
    
                        .tox-toolbar__group {
                            padding: 0;
                            background: #EBEBEB;
    
                            .tox-tbtn {
                                margin: 0;
                                padding-right: 0;
                            }
                        }
                    }
                }
    
                &.tox-pop--bottom::after {
                    border-color: #EBEBEB transparent transparent transparent
                }
            }
        }

        // AI ASSISTANT STYLES
        .tox-dialog{
            .tox-dialog__footer-end{
                .tox-button{
                    background: $primaryColor;
                    border-color: #fff;
    
                    &:hover{
                        background: #003138;
                        border-color: #fff;
    
                        &:not(:disabled) {
                            background: #003138;
                            border-color: #fff;
                        }
                    }
    
                    &.tox-button--secondary{
                        &:hover{
                            background: #c4c4c4;
                        }
                        &:not(:disabled) {
                            background: #ebebeb;
                            border-color: #fff;
                        }
    
                        &.tox-button--icon{
                            &:not(:disabled){
                                background: #003138;
                                color: #fff
                            }
                        }
                        &[title="Stop"] {
                            display: none;
                        }
                    }
    
             
                }
                
                .tox-button[disabled] {
                    background-color: #b3cdcf;
                    border-color: #fff;
                }
    
            }

            .tox-textfield{
                border-color: $primaryColor;
                &:focus{
                    border-color: $primaryColor;
                    box-shadow: none;
                }
            }

            .tox-checkbox{
                font-size: 14px;

                .tox-checkbox__icons{
                    height: 12px;
                    width: 12px;
                }
            }

        }

        // END AI ASSISTANT STYLES

    }
}