@import 'constants.scss';
@import 'commonStyles.scss';

.messengerContainer {
    height: 100vh;
    padding-right: 10px;
    transition: all 0.5s ease-in-out;

    &.emptyState {
        height: auto;
        justify-content: center;
        align-items: center;
        transition: all 0.5s ease-in-out;
    }
    
    .messagesContainer {
        height: 87vh;
        overflow: auto;
        flex-direction: column-reverse;
        margin-top: 20px;
        box-sizing: border-box;
        gap: 8px;
        
        .messageContainer {
            padding: 32px 8px 32px 40px;
            transition: all .3s ease-in-out;
            
            &.fromAI {
                background-color: #f9f9f9;

                td,
                th {
                    border: 1px solid $primaryColor;
                    padding: 3px 6px;
                    font-size: 14px;
                    text-align: left;
                }
                p {
                    margin-top: 0;
                }
            }
            .logoContainer {
                margin-right: 8px;
            }

            .message {
                width: 100%;
                white-space: pre-wrap;
            }
        }
    }

    .newMessageContainer {
        padding: 32px 0 49px 32px;
        gap: 8px;
        width: 100%;
        transition: all 0.5s ease-in-out;

        &.emptyState {
            width: 80%;
            padding: 20px 40px 20px 0;
            transition: all 0.5s ease-in-out;
            
            @media (min-width: 1440px) {
                width: 70%;
            }

            @media (min-width: 1920px) {
                width: 60%;
            }
        }

        .welcomeMessage {
            text-align: center;
            font-size: 22px;
            font-weight: 600;
            opacity: 1;
            transition: opacity 0.5s ease-in-out;
            
            .messengerContainer:not(.emptyState) & {
                opacity: 0;
            }
        }

        .textArea {
            box-shadow: 0px 0px 10px 2px rgba(196, 196, 196, 0.25);

            .sendIcon {
                cursor: pointer;
                color: #5a5a5a;
                &.disabled {
                    cursor: default;
                    color: #c4c4c4;
                }
            }
        }

        .action-btn {
            width: 30px;
            height: 30px;

            svg {
                width: 18px;
                height: 18px;
            }
        }
        // .clearHistory-btn {
        //     button {
        //         width: 34px;
        //         &:hover {
        //             background-color: $surface2;
        //         }
        //     }
        // }
    }

    .assistantStatus {
        width: fit-content;
        transition: all .3s ease-in-out;
        .assistantStatus-message {
            font-style: italic;
        }
    }
}

.assistantContainer {
    .history {
        padding: 10px 14px;
        border-right: 1px solid $surface1;
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
        position: relative;
        transition: transform 0.4s ease-in-out,
                    opacity 0.4s ease-in-out,
                    visibility 0.4s ease-in-out;

        &.closed {
            transform: translateX(-50px);
            opacity: 0;
            visibility: hidden;
            position: absolute;
        }

        .sessions {
            height: 90vh;
            overflow: auto;
        }

        .history-item {
            border-radius: 12px;
            width: 150px;
            padding: 10px 12px;
            cursor: pointer;

            .sessionActions-btn {
                opacity: 0;
            }
        
            .sessionActions-btn {
                &:global(.session-active) {
                    opacity: 1;
                }
            }

            &:hover {
                background: $surface1;
                .sessionActions-btn {
                    opacity: 1;
                }
            }

            &.disabled {
                background: $surface1;
                opacity: 0.5;
                cursor: default;
                .sessionActions-btn {
                    opacity: 0;
                }
            }

            &.active {
                background: $surface2;
                .sessionActions-btn {
                    opacity: 1;
                }
            }
        }
    }
}


.dotFlashingContainer {
    margin-top: 6px;
    padding-left: 12px;
    transition: all .3s ease-in-out;
    
    .dotFlashing {
        position: relative;
        width: 5px;
        height: 5px;
        border-radius: 5px;
        background-color: #828282;
        color: #828282;
        animation: dot-flashing 0.5s infinite linear alternate;
        animation-delay: 0.4s;

        &:before,
        &:after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            width: 5px;
            height: 5px;
            border-radius: 5px;
            background-color: #828282;
            color: #828282;
            animation: dot-flashing 0.5s infinite alternate;
        }

        &:before {
            left: -10px;
            animation-delay: 0.2s;
        }

        &:after {
            left: 10px;
            animation-delay: 0.6s;
        }
    }
}

.userFeedbackContainer {
    align-self: end;
    width: fit-content;
    margin: 12px;
    border: 1px solid $surface2;
    border-radius: 16px;
    padding: 0px 12px;
    height: 50px;
    color: $secondaryTextcolor;

    .rightBorder {
        border-right: 1px solid $surface2;
    }

    button {
        padding: 4px;
        svg {
            width: 20px;
            height: 20px;
        }
    }
}

.prdPreviewItem {
    background-color: #fafafa;
    border-radius: 10px;
    padding: 18px 20px;
    h3 {
        margin: 0;
    }
}

.prdFieldLabel {
    text-transform: capitalize;
    color: $primaryColorDark;
}

.sources-container {
    padding-top: 12px;
    .sources-title {
        font-size: 14px;
    }
}

.sources-refSidePanel {
    width: fit-content;
    padding: 6px 12px;
    background: #e9e9e9;
    text-align: center;
    cursor: pointer;
    font-size: 12px;
    border-radius: 12px;
    &:hover {
        background-color: #d5d3d3;
    }
}

.prdTitle {
    font-size: 16px;
    font-weight: 700;
}

.sourcesSidebarContainer {
    height: calc(100vh - 40px);
    overflow: hidden;

    :global(.MuiTypography-root) {
        font-size: 14px;
    }

    .sourcesSidebar-mainContent {
        height: calc(100vh - 101px);
        overflow: auto;
    }

    .sourcesSidebar-titleContainer {
        border-bottom: 1px solid $surface1;
    }
   
    .sourcesSidebar-group {
        border-radius: 16px;
        border: 1px solid  $surface2;
        padding: 16px;
    }

    .sourcesSidebar-groupItem {
        border-bottom: 1px solid $surface1;
        &.last {
            border-bottom: none;
        }
        ul {
            margin: 0;
        }
    }

    .sourcesSidebar-title {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
    }

    .sourcesSidebar-key {
        font-weight: 600;
        font-size: 15px;
    }

    .sourcesSidebar-dateKey {
        font-size: 14px;
        font-weight: 500;
    }

    .sourcesSidebar-dateValue {
        font-size: 14px;
    }

    .sourcesSidebar-item {
        background-color: $surface1;
        border: 1px solid $surface2;
        border-radius: 12px;
        overflow-x: scroll;
    }

    .sourcesSidebar-listItem, .sourcesSidebar-startItem {
        :global(.MuiTypography-root) {
            text-align: start;
        }
    }
}

.prdSectionList {
    margin: 0;
    padding-left: 20px;

    li {
        color:$primaryColor;
        margin: 2px 0px;
    }
}

.chatActionButton {
    padding: 4px 10px;
    font-size: 12px;
}

@keyframes dot-flashing {
    0% {
        background-color: #828282;
    }
    50%,
    100% {
        background-color: #c4c4c4;
    }
}
