@import 'variables.scss';

// ____________________________New Design System
/* Paragraph/Paragraph Regular */
.regular-paragraph {
    font-family: $font-family;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
}

/* Paragraph/Paragraph 2 Bold */
.paragraph-2-bold {
    font-family: $font-family;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px; /* 125% */
}

/* Body/Body Bold */
.body-bold {
    font-family: $font-family;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
}

/* Caption/Caption 2 Regular */
.caption-2-regular {
    font-family: $font-family;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

/* Paragraph/Paragraph 15/Paragraph 15 Medium */
.paragraph-15-medium {
    font-family: $font-family;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px; /* 133.333% */
}

/* Paragraph/Paragraph 15/Paragraph 15 Regular */
.paragraph-15-regular {
    font-family: $font-family;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
}